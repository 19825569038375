.App {
  min-height: 100vh;
  text-align: start;
  padding: 0.3rem 1rem 1rem;

  overflow: auto;
}

.progress-bar-with-config {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.header {
  position: sticky;
  top: 0;
  padding: 0.5rem 1rem 0.5rem;
  z-index: 1;
  border-bottom: 2px solid black;
  overflow: hidden;
}

.title {
  display: flex;

  font-weight: bold;
  align-items: center;
  justify-content: center;
  height: 2.4rem;
}

@media screen and (max-width: 320px) {
  .title {
    height: 3.2rem;
  }
}

.card {
  border-radius: 10px;
  padding: 0.25rem 0rem 1rem;
}

.card-title {
  padding-bottom: 0.4em;
  font-size: 1.3em;
  font-weight: bold;
  text-align: start;
}

.card-text {
  padding-bottom: 0.4em;
  text-align: start;
}

.card-multiple {
  border-radius: 1rem;
  padding: 0rem 1rem 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1.2fr));
  grid-gap: 0.5rem;
}
