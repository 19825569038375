.consent-term-title {
  text-align: center;

  font-weight: bolder;

  margin-bottom: 16px;
}

.consent-term-display {
  height: 100%;
}

.consent-term-info {
  margin-bottom: 1em;
  max-height: 20%;

  text-align: justify;

  overflow-y: auto;
}
