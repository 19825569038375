.checkbox {
  cursor: pointer;
  margin-right: 1em;
  accent-color: green;
  transform: scale(1.25);
}

.div-mult {
  padding: 0.2em;
}
