.dropdown-config_language {
  padding: 8px;
  display: flex;

  align-items: center;
  justify-content: space-evenly;
}

.dropdown-config_language-selector {
  padding: 0.2em 0.5em;
  border-color: black;
  border-radius: 1em;
}

.dropdown-config_theme {
  padding: 8px;
}

.dropdown-config_theme-button {
  padding: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border-color: black;
}

.side-bar-menu {
  position: fixed;

  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;

  border-right: 1px solid black;

  z-index: 1000;

  overflow: hidden;
}

@media screen and (max-width: 300px) {
  .side-bar-menu {
    width: 100vw;
  }
}

.dropdown-navbar {
  padding: 0px 1em 0px 1em;
  text-align: center;
}

.dropdown-link {
  text-decoration: none;
  width: 100%;
  padding: 4px 0px;
}

.active {
  filter: brightness(90%);
}

.dropdown-li {
  display: flex;
  width: 100%;
  padding: 8px 0px;
  font-size: 1.5em;
}

.dropdown-li :hover {
  filter: brightness(90%);
}
