.progress-bar {
  width: 100%;
  height: 1.5rem;
  border: 1px solid;
  border-color: black;
  border-radius: 0.5rem;
  color: black;
}

.fill {
  display: block;
  position: relative;
  height: 100%;
  border-radius: 0.5rem;
  transition: 0.5s;
  text-align: center;
  font-weight: bold;
}
