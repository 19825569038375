@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap);
.App {
  min-height: 100vh;
  text-align: start;
  padding: 0.3rem 1rem 1rem;

  overflow: auto;
}

.progress-bar-with-config {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 0.5rem 1rem 0.5rem;
  z-index: 1;
  border-bottom: 2px solid black;
  overflow: hidden;
}

.title {
  display: flex;

  font-weight: bold;
  align-items: center;
  justify-content: center;
  height: 2.4rem;
}

@media screen and (max-width: 320px) {
  .title {
    height: 3.2rem;
  }
}

.card {
  border-radius: 10px;
  padding: 0.25rem 0rem 1rem;
}

.card-title {
  padding-bottom: 0.4em;
  font-size: 1.3em;
  font-weight: bold;
  text-align: start;
}

.card-text {
  padding-bottom: 0.4em;
  text-align: start;
}

.card-multiple {
  border-radius: 1rem;
  padding: 0rem 1rem 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1.2fr));
  grid-gap: 0.5rem;
}


.select-option_select {
  padding: 0.5em 0.75em;
  border-color: black;
  border-radius: 1em;
}

.checkbox {
  cursor: pointer;
  margin-right: 1em;
  accent-color: green;
  transform: scale(1.25);
}

.div-mult {
  padding: 0.2em;
}

.config-btn {
  margin-right: 0.8rem;

  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
  aspect-ratio: 1;

  border: 1px solid;
  border-color: black;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: filter 200ms;
  cursor: pointer;
}

.config-btn:hover {
  filter: brightness(1.3);
}

.dropdown-config_language {
  padding: 8px;
  display: flex;

  align-items: center;
  justify-content: space-evenly;
}

.dropdown-config_language-selector {
  padding: 0.2em 0.5em;
  border-color: black;
  border-radius: 1em;
}

.dropdown-config_theme {
  padding: 8px;
}

.dropdown-config_theme-button {
  padding: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border-color: black;
}

.side-bar-menu {
  position: fixed;

  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;

  border-right: 1px solid black;

  z-index: 1000;

  overflow: hidden;
}

@media screen and (max-width: 300px) {
  .side-bar-menu {
    width: 100vw;
  }
}

.dropdown-navbar {
  padding: 0px 1em 0px 1em;
  text-align: center;
}

.dropdown-link {
  text-decoration: none;
  width: 100%;
  padding: 4px 0px;
}

.active {
  filter: brightness(90%);
}

.dropdown-li {
  display: flex;
  width: 100%;
  padding: 8px 0px;
  font-size: 1.5em;
}

.dropdown-li :hover {
  filter: brightness(90%);
}

.progress-bar {
  width: 100%;
  height: 1.5rem;
  border: 1px solid;
  border-color: black;
  border-radius: 0.5rem;
  color: black;
}

.fill {
  display: block;
  position: relative;
  height: 100%;
  border-radius: 0.5rem;
  transition: 0.5s;
  text-align: center;
  font-weight: bold;
}

.consent-term-title {
  text-align: center;

  font-weight: bolder;

  margin-bottom: 16px;
}

.consent-term-display {
  height: 100%;
}

.consent-term-info {
  margin-bottom: 1em;
  max-height: 20%;

  text-align: justify;

  overflow-y: auto;
}

body {
  margin: 0;
  font-family: Roboto, arial, Verdana, Geneva, Tahoma, sans-serif;
}

:root {
  --background-light: white;
  --text-light: black;
  --background-dark: #282c34;
  --text-dark: aliceblue;
}

.dark-theme {
  background-color: #282c34;
  background-color: var(--background-dark);
  color: aliceblue;
  color: var(--text-dark);
}

.light-theme {
  background-color: white;
  background-color: var(--background-light);
  color: black;
  color: var(--text-light);
}

