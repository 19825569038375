.config-btn {
  margin-right: 0.8rem;

  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
  aspect-ratio: 1;

  border: 1px solid;
  border-color: black;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: filter 200ms;
  cursor: pointer;
}

.config-btn:hover {
  filter: brightness(1.3);
}
